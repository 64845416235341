<template>
  <section :class="[{ subsection: isSubSection }, isDark ? 'dark' : 'light']">
    <h2
      :class="[
        'header-2',
        'header-bold',
        isDark ? 'dark-header' : 'light-header',
      ]"
      :v-show="superTitle != undefined"
    >
      {{ superTitle }}
    </h2>
    <h3
      v-if="isSubSection == false"
      :class="[
        !isStyled ? 'header-bold' : '',
        'header-3',
        isDark ? 'dark-header' : 'light-header',
      ]"
      v-html="title"
    ></h3>
    <h4
      v-if="isSubSection == true"
      :class="[
        !isStyled ? 'header-bold' : '',
        'header-4',
        isDark ? 'dark-header' : 'light-header',
      ]"
      v-html="title"
    ></h4>
    <p :class="isDark ? 'dark-body' : 'light-body'" v-html="body"></p>
    <slot />
  </section>
</template>

<script>
import { store } from "@/store/store";

export default {
  name: "Section",
  store: store,
  props: {
    isStyled: {
      type: Boolean,
      default: false,
    },
    isSubSection: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    superTitle: {
      type: String,
    },
    body: {
      type: String,
    },
    subsections: {
      type: Array,
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1.6;
}

.dark {
  color: var(--color-gray-25);
}

.light {
  color: var(--color-basic-black);
}

section {
  text-align: left;
  display: grid;
  gap: 3rem;
}

.subsection {
  gap: 1rem;
}
</style>

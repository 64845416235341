<template>
  <div class="coding-container">
    <div class="section-header">
      <SectionHeader headerSize="xSmall" headerText="software engineering" />
    </div>
    <div class="s-header-mid">
      <SectionHeader
        :is-large="false"
        headerSize="xSmall"
        headerText="software engineering"
      />
    </div>
    <!-- Section Header -->
    <div class="right-side">
      <Section :body="codingData.overview" title="Overview" />
      <!-- Overview -->
      <div class="body">
        <Section
          :body="codingData.intro.description"
          :title="codingData.intro.title"
        />
        <div class="break-down">
          <LanguageGroup
            v-for="(item, index) in codingData.breakdown"
            :key="index"
            :langData="item"
            :title="item.name"
          />
        </div>
        <!-- Break down -->
      </div>
      <!-- Body -->
    </div>
    <!-- Right Side -->
    <BackToTop />
  </div>
</template>

<script>
import { basicInnerPageMixin } from "@/core/mixins/inner_page_mixin.js";
import { writtenData } from "@/core/mixins/written_data_mixin.js";
import LanguageGroup from "@/components/info/LanguageGroup.vue";
import { scrollMixin } from "@/core/mixins/scroll_mixin";
import Section from "@/components/info/desktop/Section.vue";

export default {
  mixins: [basicInnerPageMixin, writtenData, scrollMixin],
  components: {
    Section,
    LanguageGroup,
  },
};
</script>

<style scoped>
.coding-container {
  inline-size: 100%;
  block-size: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 18.25rem 1fr;
}

@media screen and (max-width: 875px) {
  .coding-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    margin-block-start: 4rem;
    place-items: start;
  }
}

.section-header {
  margin-block-start: 5rem;
  block-size: fit-content;
  inline-size: fit-content;
}

@media screen and (max-width: 875px) {
  .section-header {
    display: none;
  }
}

.s-header-mid {
  display: none;
}

@media screen and (max-width: 875px) {
  .s-header-mid {
    display: grid;
    block-size: 100%;
    inline-size: 100%;
    padding-inline: 2rem;
    place-content: start;
  }
}

.break-down {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 4rem;
}

.body {
  display: grid;
  grid-template-rows: 14rem auto;
}

.right-side {
  inline-size: 100%;
  max-inline-size: 51rem;
  box-sizing: border-box;
  padding-inline-end: 2rem;
}

@media screen and (max-width: 875px) {
  .right-side {
    inline-size: 100%;
    max-inline-size: 51rem;
    padding-inline: 2rem;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[{ subsection: _vm.isSubSection }, _vm.isDark ? 'dark' : 'light']},[_c('h2',{class:[
      'header-2',
      'header-bold',
      _vm.isDark ? 'dark-header' : 'light-header',
    ],attrs:{"v-show":_vm.superTitle != undefined}},[_vm._v(" "+_vm._s(_vm.superTitle)+" ")]),(_vm.isSubSection == false)?_c('h3',{class:[
      !_vm.isStyled ? 'header-bold' : '',
      'header-3',
      _vm.isDark ? 'dark-header' : 'light-header',
    ],domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.isSubSection == true)?_c('h4',{class:[
      !_vm.isStyled ? 'header-bold' : '',
      'header-4',
      _vm.isDark ? 'dark-header' : 'light-header',
    ],domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_c('p',{class:_vm.isDark ? 'dark-body' : 'light-body',domProps:{"innerHTML":_vm._s(_vm.body)}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }